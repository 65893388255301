import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import PageHeader from "../components/PageHeader"
import { Main, Container } from "../components/Elements"

import FbCircle from "../assets/svgs/FbCircle"
import InstagramCircle from "../assets/svgs/InstagramCircle"

const ContactPage = () => {
  const { heroImage } = useStaticQuery(graphql`
    query ContactPageQuery {
      heroImage: file(relativePath: { eq: "hero/contact.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, quality: 90)
        }
      }
    }
  `)

  const heroImageData = getImage(heroImage)

  return (
    <Main>
      <Seo
        title="Contact us | Don't hesitate, get in touch."
        desc="Have a question? Get in touch!"
      />

      <PageHeader image={heroImageData} title="Contact" text="Get in touch" />

      <Container>
        <h2 className="heading">Have a question? Get in touch!</h2>
        <p className="mt-2 leading-7">
          Don’t hesitate to contact us via phone or email. The message will be
          sent directly to us and be responded to as soon as possible.
        </p>
        <div className="mt-10 pb-14 grid gap-y-6 gap-x-10 lg:grid-cols-2 leading-10 lg:leading-8">
          <div>
            <h3 className="heading">Call us for a free consultation</h3>
            <p className="mt-2 mb-1 leading-7">
              <a href="tel:07568359825" className="link">
                07568359825
              </a>
            </p>

            <h3 className="heading mt-6">Email us</h3>
            <p className="my-1 leading-7">
              <a
                href="mailto:ascensionsportsmassage@gmail.com"
                className="link"
              >
                Ascensionsportsmassage@gmail.com
              </a>
            </p>

            <h3 className="heading mt-6">Social media</h3>
            <p className="my-1 flex space-x-4">
              <a
                href="https://www.facebook.com/Ascension-Sports-Massage-104036905072281"
                className="text-b-orange hover:text-b-orange-light"
                rel="noopener noreferrer"
                target="__blank"
              >
                <span className="sr-only">Facebook</span>
                <FbCircle className="h-16 w-16" />
              </a>
              <a
                href="https://www.instagram.com/Ascensionsportsmassage/"
                className="text-b-orange hover:text-b-orange-light"
                rel="noopener noreferrer"
                target="__blank"
              >
                <span className="sr-only">Instagram</span>
                <InstagramCircle className="h-16 w-16" />
              </a>
            </p>
          </div>
          <div>
            <h3 className="heading">Opening hours</h3>
            <p className="mt-2">
              Opening hours change frequently, check social media pages for
              regular updates or call for your free consultation and I will do
              my best to get you booked in as soon as possible.
            </p>
          </div>
        </div>
      </Container>
    </Main>
  )
}

export default ContactPage
